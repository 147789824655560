<template>

  <div class="row" ref="roleForm">

    <div class="col-xs-8 offset-xs-2 col-md-10 offset-md-1 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        :label="$store.getters['languageMixin/getStringFromLanguage']('Name')"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <div class="row table-full-width">
              <h6 class="mb-3">{{ $store.getters['languageMixin/getStringFromLanguage']('Permissions')}}:</h6>
              <div class="col-12" v-for="(group, index) in filterBuilders.groups" style="margin-bottom: 20px">
                <el-collapse>
                  <el-collapse-item :title="group.name" name="1">

                    <div class="permissions-type-active">
                      <label>{{ $store.getters['languageMixin/getStringFromLanguage']('Select All') }}</label>
                      <l-switch
                        v-model="globalPermissions[index]"
                        @input="checkGlobalPermissions(index)"
                        :name="index+'global'"
                      >
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </l-switch>
                    </div>
                    <div class="col-sm-12">
                      <el-table class="table"
                                :data="group.permissions">
                        <el-table-column v-for="column in tableColumns"
                                         :key="column.label"
                                         :min-width="column.minWidth"
                                         :align="column.align"
                                         :sortable="column.sortable"
                                         :prop="column.value"
                                         :label="column.label"
                        >

                        </el-table-column>

                        <el-table-column
                          :min-width="120"
                          fixed="right"
                          align="center"
                          :label="$store.getters['languageMixin/getStringFromLanguage']('Active')">
                          <template slot-scope="props">

                            <l-switch
                              v-model="props.row.is_active">
                              <i class="fa fa-check" slot="on"></i>
                              <i class="fa fa-times" slot="off"></i>
                            </l-switch>

                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Submit') }}
            </l-button>
            <l-button @click="$router.push('/roles/list')" type="danger" wide>{{ $store.getters['languageMixin/getStringFromLanguage']('Cancel') }}
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Collapse, CollapseItem, Option, Select, Table, TableColumn} from 'element-ui';
import {Switch as LSwitch} from '@/components'
import {Pagination as LPagination} from 'src/components/index'
import FgSelect from "@/components/Inputs/formGroupSelect";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    LPagination,
    FgSelect,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
  },
  data() {
    return {
      tableData:[],
      fuseSearch: null,
      propsToSearch: ['title'],
      searchQuery: '',
      tableColumns: [
        {label: this.$store.getters['languageMixin/getStringFromLanguage']('Title'), value: 'title', minWidth: '200', align: 'center'},
      ],
      editMode: false,
      loader: {},
      id: undefined,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        name: "",
        permissions: [],
      },
      permissions: [],
      filterBuilders: {
          groups: []
      },

      filters: {
          group_id: null
      },
      ownPermissions: [],
      globalPermissions: [],
    };
  },
   mounted() {
    let data = {
      'lang': this.lang
    }
    this.loader = this.$loading.show({
      container: this.$refs.roleForm
    });
    this.axios.post("roles/builder", data).then( (response) => {
      this.filterBuilders.groups = response.data.groups;
      for (const e of this.filterBuilders.groups) {
        e.permissions = e.permissions.map( (item) => {return {...item,'is_active':false}});
      }
      this.id = this.$route.params['id'];
      this.loader.hide();

      if (this.id !== undefined) {

        this.editMode = true;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Edit Role");
        this.getRole();

      } else {
        this.editMode = false;
        this.formTitle = this.$store.getters['languageMixin/getStringFromLanguage']("Add Role");
        this.setGlobalAndOwn();
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    });
  },


  methods: {


    getRole() {
      this.axios.get("roles/get/" + this.id).then((response) => {
        this.formData.name = response.data.name;
        this.formData.permissions = response.data.permissions.map(p => {
          return p.id
        });
        this.filterBuilders.groups.forEach(e => {
          e.permissions.forEach(item =>{
            item.is_active = this.formData.permissions.includes(item.id);
          })
        })
        this.setGlobalAndOwn();
        this.loader.hide();
      }).catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: this.$store.getters['languageMixin/getStringFromLanguage']("Role Not Found"),
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let ids = [];
      this.filterBuilders.groups.forEach(e => {
        ids.push(...e.permissions.filter(element => element.is_active === true));
      });
      ids = ids.map(p => {
        return p.id
      });

      if (this.editMode === true) {

        request = this.axios.put("roles/update/" + this.id, {name: this.formData.name, permissions: ids});
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Role Updated Successfully");
      } else {
        request = this.axios.post("roles/create", {name: this.formData.name, permissions: ids});
        successMessage = this.$store.getters['languageMixin/getStringFromLanguage']("Role Added Successfully");
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/roles/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    checkOwnPermissions(index){
      this.filterBuilders.groups[index].permissions.forEach(e => {
        if (e.title.toLowerCase().includes('own') || e.title.toLowerCase().includes('create')){
          e.is_active = this.ownPermissions[index];
        }

      })
    },
    checkGlobalPermissions(index){
      this.filterBuilders.groups[index].permissions.forEach(e => {
        if (!e.title.toLowerCase().includes('own')){
          e.is_active = this.globalPermissions[index];
        }

      })
    },
    setGlobalAndOwn(){
      for (const e of this.filterBuilders.groups) {
        const index = this.filterBuilders.groups.indexOf(e);
        this.ownPermissions[index] = this.checkIfHaveAllOwn(index);
        this.globalPermissions[index] = this.checkIfHaveAllGlobal(index);
      }
    },
    checkIfHaveAllGlobal(groupIndex){
      for (const perm of this.filterBuilders.groups[groupIndex].permissions){
        if (!perm.is_active  && !perm.title.toLowerCase().includes('own')){
          return false;
        }
      }
      return true;
    },
    checkIfHaveAllOwn(groupIndex){
      for (const perm of this.filterBuilders.groups[groupIndex].permissions){
        if (!perm.is_active  && (perm.title.toLowerCase().includes('own') || perm.title.toLowerCase().includes('create'))){
          return false;
        }
      }
      return true;
    },
  }
}
</script>

<style>
.permissions-type-active{
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
}
.permissions-type-active label{
  margin-left: 35px;
}
</style>
